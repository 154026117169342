
/**
 * Export `colors`.
 */

export const colors = {
  beige: 'beige',
  black: 'black',
  blue: 'blue',
  brown: 'brown',
  gold: 'gold',
  green: 'green',
  grey: 'grey',
  metal: 'metal',
  orange: 'orange',
  pink: 'pink',
  purple: 'purple',
  red: 'red',
  white: 'white',
  yellow: 'yellow'
} as const;

/**
 * Export `colorValues`.
 */

export const colorValues = {
  [colors.beige]: '#d0bda6',
  [colors.black]: '#000000',
  [colors.blue]: '#1dafec',
  [colors.brown]: '#765b4a',
  [colors.gold]: '#d3ae43',
  [colors.green]: '#17a554',
  [colors.grey]: '#707070',
  [colors.metal]: '#b7b7b7',
  [colors.orange]: '#f59331',
  [colors.pink]: '#fd6cb4',
  [colors.purple]: '#542189',
  [colors.red]: '#c11f23',
  [colors.white]: '#ffffff',
  [colors.yellow]: '#fef035'
};
