
/**
 * Module dependencies.
 */

import { useRouter } from 'next/router';
import Head from 'next/head';
import React, { ReactElement, useMemo } from 'react';
import useConfigurations from 'src/api/app/configurations/use-configurations';

/**
 * Constants.
 */

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL as string;
const defaultTitle = 'Revigrés';
const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE as string;
const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

/**
 * Export `HrefLang` type.
 */

export type HrefLang = {
  lang: string;
  url: string;
};

/**
 * `Props` type.
 */

type Props = {
  canonicalUrl?: string,
  description?: string,
  hrefLangs?: HrefLang[] | null,
  image?: string,
  isKiosk?: boolean,
  isProductContact?: boolean,
  keywords?: string,
  pageTitle?: string,
  title?: string
};

/**
 * `Metatags` component.
 */

const Metatags = (props: Props): ReactElement => {
  const {
    canonicalUrl,
    description,
    hrefLangs,
    image,
    isKiosk = false,
    isProductContact = false,
    keywords,
    pageTitle,
    title
  } = props;

  const { data } = useConfigurations();
  const router = useRouter();
  const ogImage = useMemo(() => data?.ogImage?.fileUrl, [data]);
  const imageUrl = image ?? ogImage;
  const normalizedPageTitle = useMemo(() => {
    if (pageTitle && pageTitle === defaultTitle) {
      return pageTitle;
    }

    if (title) {
      return title;
    }

    if (pageTitle || pageTitle === title) {
      return `${pageTitle} - ${defaultTitle}`;
    }

    return defaultTitle;
  }, [pageTitle, title]);

  const normalizedHrefLangs = useMemo(() => {
    return hrefLangs?.sort((lang1, lang2) => {
      return router.locales.indexOf(lang1.lang) - router.locales.indexOf(lang2.lang);
    });
  }, [hrefLangs, router.locales]);

  const pageUrl = useMemo(() => {
    return normalizedHrefLangs?.find(({ lang }) => lang === router.locale)?.url || baseUrl;
  }, [normalizedHrefLangs, router.locale]);

  const hrefLangDefault = useMemo(() => {
    return normalizedHrefLangs?.find(({ lang }) => lang === defaultLocale)?.url;
  }, [normalizedHrefLangs]);

  return (
    <Head>
      <title>
        {normalizedPageTitle}
      </title>

      {description && (
        <meta
          content={description}
          name={'description'}
        />
      )}

      {keywords && (
        <meta
          content={keywords}
          name={'keywords'}
        />
      )}

      {imageUrl && (
        <meta
          content={imageUrl}
          name={'image'}
        />
      )}

      <meta
        content={normalizedPageTitle}
        property={'og:title'}
      />

      {description && (
        <meta
          content={description}
          property={'og:description'}
        />
      )}

      {imageUrl && (
        <meta
          content={imageUrl}
          property={'og:image'}
        />
      )}

      <meta
        content={pageUrl}
        property={'og:url'}
      />

      <meta
        content={defaultTitle}
        property={'og:site_name'}
      />

      <meta
        content={'website'}
        property={'og:type'}
      />

      <meta
        content={normalizedPageTitle}
        name={'twitter:title'}
      />

      {description && (
        <meta
          content={description}
          name={'twitter:description'}
        />
      )}

      {imageUrl && (
        <meta
          content={imageUrl}
          name={'twitter:image'}
        />
      )}

      <meta
        content={'summary_large_image'}
        name={'twitter:card'}
      />

      <meta
        content={'Untile, hello@untile.pt'}
        name={'author'}
      />

      <meta
        content={defaultTitle}
        name={'copyright'}
      />

      <link
        href={canonicalUrl ? canonicalUrl : pageUrl}
        rel={'canonical'}
      />

      {!isKiosk && normalizedHrefLangs?.map(({ lang, url }) => (
        <link
          href={url}
          hrefLang={lang}
          key={url + lang}
          rel={'alternate'}
        />
      ))}

      {!isKiosk && (
        <link
          href={hrefLangDefault}
          hrefLang={'x-default'}
          rel={'alternate'}
        />
      )}

      <meta
        content={isProduction && !isKiosk && !isProductContact ? 'index, follow' : 'noindex, nofollow'}
        name={'robots'}
      />
    </Head>
  );
};

/**
 * Export `Metatags` component.
 */

export default Metatags;
