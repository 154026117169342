
/**
 * Module dependencies.
 */

import { CollectionProps, MediaProps } from 'src/types/collection';
import { assetUrlResolve } from 'src/core/utils/url-resolver';
import { color, units } from 'src/styles/utils';
import { ifNotProp, prop, switchProp, theme } from 'styled-tools';
import { kioskRoutes } from 'src/core/routes';
import { mediasCollectionName } from 'src/core/collections-config/media';
import { normalizeColors } from 'src/core/utils/colors';
import { routeTemplate, useLocalizedRoute } from 'src/core/utils/routes';
import { useTranslate } from 'src/core/utils/translator';
import CardTitle from 'src/components/core/typography/card-title';
import Image, { ImageProps } from 'src/components/core/image';
import Label from 'src/components/core/typography/label';
import React, { ReactElement, useMemo } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Type from 'src/components/core/typography/index';
import filter from 'lodash/filter';
import head from 'lodash/head';
import size from 'lodash/size';
import styled, { css } from 'styled-components';
import uniqBy from 'lodash/uniqBy';

/**
 * `BgColorTheme` type.
 */

type BgColorTheme = 'white' | 'grey';

/**
 * `Props` type.
 */

type Props = CollectionProps & {
  bgColorTheme?: BgColorTheme,
  isKioskCard?: boolean
};

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)<ImageProps>`
  transition: transform 5s ease-in;
`;

/**
 * `Card` styled component.
 */

const Card = styled(RouterLink)<{ bgColorTheme: BgColorTheme }>`
  background-color: ${color('white')};
  color: ${color('textColor')};
  display: grid;
  grid-template-areas:
    'image image image'
    '.     .         .'
    '.     title     .'
    '.     .         .'
    '.     color     .'
    '.     formats   .'
    '.     finishes  .';
  grid-template-columns: ${units(3)} 1fr ${units(3)};
  grid-template-rows: 288px ${units(3)} 2fr ${units(3)} repeat(3, auto);
  height: 100%;
  padding-bottom: ${units(3)};
  transition: color ${theme('animations.defaultTransition')};

  ${switchProp('bgColorTheme', {
    grey: css`
      background-color: ${color('grey100')};
    `,
    white: css`
      background-color: ${color('white')};
    `
  })}

  &:focus,
  &:hover {
    color: ${color('brown400')};

    ${StyledImage} {
      transform: scale(1.5);
    }
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-area: image;
  height: 288px;
  overflow: hidden;
  position: relative;
`;

/**
 * `StyledLabel` styled component.
 */

const StyledLabel = styled(Label).attrs({ size: 'small' })`
  color: ${color('brown400')};
  font-style: italic;
  grid-area: label;
  min-height: ${units(2)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(CardTitle).attrs({ size: 'small' })<{ hasNew: boolean }>`
  grid-area: title;

  ${ifNotProp('hasNew', css`
    padding-top: ${units(2)};
  `)}
`;

/**
 * `Spec` styled component.
 */

const Spec = styled.div<{ gridArea: string }>`
  display: grid;
  grid-area: ${prop('gridArea')};
  grid-gap: ${units(1)};
  grid-template-areas: 'amount label';
  grid-template-columns: ${units(2)} auto;

  &:not(:last-child) {
    margin-bottom: ${units(1)};
  }
`;

/**
 * `SpecAmount` styled component.
 */

const SpecAmount = styled(Label).attrs({ as: 'span' })`
  color: ${color('brown400')};
  grid-area: amount;
`;

/**
 * `SpecLabel` styled component.
 */

const SpecLabel = styled(Type.Small).attrs({ as: 'span', xSmall: true })`
  color: ${color('grey300')};
  grid-area: label;
  line-height: ${units(2)};
  text-transform: uppercase;
`;

/**
 * Get image.
 */

function getImage(media: MediaProps[]): string {
  const image = head(filter(media, {
    collectionName: mediasCollectionName.featuredImages
  }));

  return image?.fileUrl ?? assetUrlResolve('/static/images/empty/list@2x.jpg');
}

/**
 * `CollectionCard` component.
 */

const CollectionCard = (props: Props): ReactElement => {
  const {
    bgColorTheme,
    colors,
    finishes,
    formats,
    isKioskCard,
    media,
    name,
    new:
    isNew,
    slug
  } = props;

  const colorsTotal = useMemo(() => {
    const colorImages = filter(media, {
      collectionName: mediasCollectionName.colorImage
    });

    const normalizedColors = normalizeColors(colors, colorImages);
    const total = size(normalizedColors);

    return total > 0 ? total : size(colors);
  }, [colors, media]);

  const formatsTotal = useMemo(() => {
    const list = uniqBy(formats, 'name');

    return list?.length ?? 0;
  }, [formats]);

  const { locale, translate } = useTranslate();
  const routeResolver = useLocalizedRoute();
  const collectionRoute = routeResolver('collection', { slug });
  const kioskCollectionRoute = routeTemplate(kioskRoutes.collection, { slug });
  const specs = useMemo(() => [{
    amount: colorsTotal,
    label: translate('common:labels.color', { count: colorsTotal }),
    name: 'color'
  }, {
    amount: formatsTotal,
    label: translate('common:labels.format', { count: formatsTotal }),
    name: 'formats'
  }, {
    amount: finishes?.length ?? 0,
    label: translate('common:labels.finish', { count: finishes?.length }),
    name: 'finishes'
  }], [colorsTotal, finishes.length, formatsTotal, translate]);

  return (
    <Card
      bgColorTheme={bgColorTheme}
      href={isKioskCard ? kioskCollectionRoute : collectionRoute}
      locale={locale}
    >
      <ImageWrapper>
        <StyledImage
          alt={name}
          layout={'fill'}
          objectFit={'cover'}
          src={getImage(media)}
        />
      </ImageWrapper>

      <Title hasNew={isNew}>
        {isNew && (
          <StyledLabel>
            {translate('common:labels.new')}
          </StyledLabel>
        )}

        {name}
      </Title>

      {specs.map(({ amount, label, name }) => (
        <Spec
          gridArea={name}
          key={name}
        >
          <SpecAmount>
            {amount}
          </SpecAmount>

          <SpecLabel>
            {label}
          </SpecLabel>
        </Spec>
      ))}
    </Card>
  );
};

/**
 * Default props.
 */

CollectionCard.defaultProps = {
  bgColorTheme: 'white'
};

/**
 * Export `CollectionCard` component.
 */

export default CollectionCard;
