
/**
 * Export `mediasMagazineName`.
 */

export const mediasMagazineName = {
  featuredImages: 'featured_images',
  gallery: 'gallery',
  videoImage: 'video_image'
} as const;
