
/**
 * Module dependencies.
 */

import { CSSProperties } from 'src/types/css';
import { fontSettings } from './';
import { setFontStyle } from 'src/styles/utils/typography';
import { setStyledProps } from 'src/styles/utils/props';
import { switchProp } from 'styled-tools';
import { typography } from 'src/styles/type';
import styled from 'styled-components';

/**
 * Type sizes.
 */

const typeSizes = {
  large: {
    fontFamily: typography.fontFamily.sansSerif,
    fontSize: 40,
    fontSizeMax: 40,
    fontSizeMin: 32,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 48
  },
  medium: {
    fontFamily: typography.fontFamily.sansSerif,
    fontSize: 32,
    fontSizeMax: 32,
    fontSizeMin: 20,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 40
  },
  small: {
    fontFamily: typography.fontFamily.sansSerif,
    fontSize: 24,
    fontSizeMax: 24,
    fontSizeMin: 20,
    fontWeight: 400,
    letterSpacing: 1,
    lineHeight: 30
  }
};

/**
 * Export `CardTitleSizeType` type.
 */

type TypeSizes = typeof typeSizes;
export type CardTitleSizeType = keyof TypeSizes;

/**
 * `CardTitle` styled component.
 */

const CardTitle = styled.div<CSSProperties & { size?: CardTitleSizeType }>`
  ${switchProp('size', {
    large: setFontStyle(typeSizes.large),
    medium: setFontStyle(typeSizes.medium),
    small: setFontStyle(typeSizes.small)
  })}

  ${fontSettings}
  ${setStyledProps}
`;

/**
 * Default props.
 */

CardTitle.defaultProps = {
  size: 'medium'
};

/**
 * Export `CardTitle` component.
 */

export default CardTitle;
