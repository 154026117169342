
/**
 * Module dependencies.
 */

import { setStyledProps } from 'src/styles/utils/props';
import styled, { StyledComponentBase } from 'styled-components';

/**
 * `Box` component.
 */

const Box: StyledComponentBase<any, Record<string, unknown>> = styled.div`
  ${setStyledProps}
`;

/**
 * Export `Box` component.
 */

export default Box;
