
/**
 * Module dependencies.
 */

import { MediaProps, ProductColor } from 'src/types/products';
import { colors } from 'src/styles/colors';
import { colors as configuredColors } from 'src/core/collections-config/colors';
import filter from 'lodash/filter';
import head from 'lodash/head';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import map from 'lodash/map';

/**
 * Export `modulesThemeConfig`.
 */

export const modulesThemeConfig = {
  dark: [
    colors.grey100,
    colors.grey200,
    colors.beige100,
    colors.beige150,
    colors.beige200
  ],
  light: [
    colors.black,
    colors.grey300,
    colors.grey400,
    colors.green100,
    colors.green400,
    colors.brown200,
    colors.brown300,
    colors.brown400
  ]
};

/**
 * Export `getModuleTheme`.
 */

export function getModuleTheme(color: string | null | undefined) {
  if (!color) {
    return null;
  }

  const themes = keys(modulesThemeConfig);

  return head(filter(themes, theme => {
    return modulesThemeConfig[theme].includes(color.toLowerCase());
  }));
}

/**
 * Export `normalizeColors`.
 */

export function normalizeColors(colors: ProductColor[], colorImages: MediaProps[]) {
  const validColors = filter(colors, (colorName: string) => {
    return includes(Object.values(configuredColors), colorName);
  });

  const validColorImages = filter(colorImages, ({ customProperties }) => {
    return includes(validColors, customProperties?.color);
  });

  return map(validColorImages, ({ customProperties, fileUrl, id }) => ({
    ...customProperties,
    fileUrl,
    id
  }));
}
