
/**
 * Module depenencies.
 */

import { breakpoints } from 'src/styles/breakpoints';
import { color, media, units } from 'src/styles/utils';
import { theme } from 'styled-tools';
import Container from 'src/components/core/layout/container';
import GalleryNavigation from 'src/components/core/slider/gallery-navigation';
import Image from 'src/components/core/image';
import Label from 'src/components/core/typography/label';
import Lightbox from 'src/components/lightbox';
import ParallaxFadeInUp from 'src/components/core/animations/parallax-fade-in-up';
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';

import Slider, { Slide } from 'src/components/core/slider';
import Type from 'src/components/core/typography';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import styled from 'styled-components';

/**
 * `Item` type.
 */

type Item = {
  id: string
  title?: string | null,
  url: string
};

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  isKiosk?: boolean,
  items: Item[],
  label?: string
};

/**
 * `Section` component.
 */

const Section = styled.section`
  background-color: ${color('white')};
  padding: ${units(13)} 0;
  position: relative;

  ${media.min('ms')`
    padding: ${units(25)} 0;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  padding-right: 0;

  ${media.min('ms')`
    padding-right: 0;
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'gallery'
    'navigation'
    'scrollbar';
  grid-template-columns: 100%;

  ${media.min('ms')`
    grid-template-areas:
      '. gallery    gallery'
      '. navigation .'
      '. scrollbar  .';
    grid-template-columns: calc(100% / 12) calc(100% / 12 * 10) calc(100% / 12);
  `}
`;

/**
 * `Gallery` styled component.
 */

const Gallery = styled.div`
  grid-area: gallery;
  padding-left: ${units(6)};
  position: relative;
  width: 100%;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Label).attrs({ size: 'small' })`
  color: ${color('brown400')};
  font-style: italic;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-90deg) translate(-100%, 0);
  transform-origin: 0 0;
`;

/**
 * `SlideImage` styled component.
 */

const SlideImage = styled.div`
  cursor: pointer;
  padding-bottom: 100%;
  position: relative;
`;

/**
 * `SlideLabel` styled component.
 */

const SlideLabel = styled(Type.Small).attrs({ as: 'div', xSmall: true })`
  color: ${color('grey300')};
  padding: 18px ${units(1.5)};
`;

/**
 * `StyledGalleryNavigation` styled component.
 */

const StyledGalleryNavigation = styled(GalleryNavigation)`
  display: none;
  grid-area: navigation;

  ${media.min('ms')`
    display: flex;
    margin-bottom: ${units(1)};
    padding-top: ${units(3)};
  `}
`;

/**
 * `Scrollbar` styled component.
 */

const Scrollbar = styled.div`
  grid-area: scrollbar;
  padding: 30px ${theme('grid.gutterMobile')}px 30px 0;
  position: relative;

  ${media.min('ms')`
    padding-right: ${theme('grid.gutter')}px;
  `}

  .images-gallery-scrollbar {
    background-color: ${color('grey200')};
    height: 1px;
  }

  .images-gallery-scrollbar-drag {
    background: ${color('brown400')};
    height: 1px;
  }
`;

/**
 * Slider responsive config.
 */

const sliderResponsiveConfig = {
  breakpoints: {
    [breakpoints.sm]: {
      slidesPerView: 1.5,
      spaceBetween: 24
    },
    [breakpoints.ms]: {
      centeredSlides: true,
      slidesPerView: 1.75,
      spaceBetween: 38
    },
    [breakpoints.md]: {
      centeredSlides: true,
      slidesPerView: 2,
      spaceBetween: 52
    },
    [breakpoints.lg]: {
      centeredSlides: true,
      slidesPerView: 2.5,
      spaceBetween: 66
    },
    [breakpoints.xl]: {
      centeredSlides: true,
      slidesPerView: 2.5,
      spaceBetween: 80
    }
  }
};

/**
 * `ImagesGallery` component.
 */

const ImagesGallery = (props: Props): ReactElement | null => {
  const { className, isKiosk, items, label } = props;
  const [clickedSlide, setClickedSlide] = useState<number>(0);
  const [isOpenLightbox, setOpenLightbox] = useState<boolean>();
  const normalizedItems = useMemo(() => {
    return filter(items, ({ url }) => !isEmpty(url));
  }, [items]);

  const sliderRef = useRef<any>();
  const total = size(normalizedItems);
  const handleClickSlide = useCallback(({ clickedIndex }) => {
    setClickedSlide(clickedIndex);
    setOpenLightbox(true);
  }, []);

  const handlePrevious = useCallback(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.swiper.slidePrev();
    }
  }, []);

  const handleNext = useCallback(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.swiper.slideNext();
    }
  }, []);

  if (isEmpty(normalizedItems)) {
    return null;
  }

  return (
    <>
      <Section className={className}>
        <StyledContainer
          fluid
          hasPadding={!isKiosk}
        >
          <Grid>
            <Gallery>
              {label && (
                <ParallaxFadeInUp parallaxOptions={{ percentage: 0.1 }}>
                  <Title>
                    {label}
                  </Title>
                </ParallaxFadeInUp>
              )}

              <Slider
                onClick={handleClickSlide}
                slidesPerView={1.25}
                spaceBetween={24}
                {...sliderResponsiveConfig}
                ref={sliderRef}
                scrollbar={{
                  dragClass: 'images-gallery-scrollbar-drag',
                  el: '.images-gallery-scrollbar',
                  hide: false
                }}
              >
                {normalizedItems.map(({ id, title, url }: Item) => (
                  <Slide key={id}>
                    <SlideImage>
                      <Image
                        alt={title}
                        layout={'fill'}
                        objectFit={'cover'}
                        src={url}
                      />
                    </SlideImage>

                    <SlideLabel>
                      {title}
                    </SlideLabel>
                  </Slide>
                ))}
              </Slider>
            </Gallery>

            <StyledGalleryNavigation
              onGoToNext={handleNext}
              onGoToPrevious={handlePrevious}
              total={total}
            />

            <Scrollbar>
              <div className={'images-gallery-scrollbar'} />
            </Scrollbar>
          </Grid>
        </StyledContainer>
      </Section>

      {total > 0 && (
        <Lightbox
          initialSlide={clickedSlide}
          isOpen={isOpenLightbox}
          items={map(items, ({ title, url: fileUrl }) => ({
            label: title,
            type: 'image',
            url: fileUrl
          }))}
          onClose={() => setOpenLightbox(false)}
        />
      )}
    </>
  );
};

/**
 * Export `ImagesGallery` component.
 */

export default ImagesGallery;
