
/**
 * Export `categories`.
 */

export const categories = {
  event: 'event',
  news: 'news',
  opinionArticles: 'opinionArticles',
  trends: 'trends'
};

/**
 * Export `categoriesValues`.
 */

export const categoriesValues = {
  [categories.event]: 'event',
  [categories.news]: 'news',
  [categories.opinionArticles]: 'opinion articles',
  [categories.trends]: 'trends'
};
