
/**
 * Module dependencies.
 */

import { MagazineProps } from 'src/types/magazine';
import { assetUrlResolve } from 'src/core/utils/url-resolver';
import { categoriesValues } from 'src/core/magazine-config/categories';
import { color, units } from 'src/styles/utils';
import { getKeyFromObject } from 'src/core/utils/objects';
import { mediasMagazineName } from 'src/core/magazine-config/media';
import { switchProp, theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useTranslate } from 'src/core/utils/translator';
import CardTitle from 'src/components/core/typography/card-title';
import Image, { ImageProps } from 'src/components/core/image';
import Label from 'src/components/core/typography/label';
import React, { ReactElement, useMemo } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Type from 'src/components/core/typography';
import filter from 'lodash/filter';
import head from 'lodash/head';
import styled, { css } from 'styled-components';

/**
 * `BgColorTheme` type.
 */

type BgColorTheme = 'white' | 'grey';

/**
 * `Props` type.
 */

type Props = MagazineProps & {
  bgColorTheme?: BgColorTheme
};

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)<ImageProps>`
  transition: transform 5s ease-in;
`;

/**
 * `Card` styled component.
 */

const Card = styled(RouterLink)<{ bgColorTheme: BgColorTheme }>`
  background-color: ${color('grey100')};
  color: ${color('textColor')};
  cursor: pointer;
  display: grid;
  grid-template-areas:
    'image image image'
    '.     info  .';
  grid-template-columns: ${units(3)} 1fr ${units(3)};
  grid-template-rows: ${units(36)} ${units(28)};
  padding-bottom: ${units(3)};
  transition: color ${theme('animations.defaultTransition')};

  ${switchProp('bgColorTheme', {
    grey: css`
      background-color: ${color('grey100')};
    `,
    white: css`
      background-color: ${color('white')};
    `
  })}

  &:focus,
  &:hover {
    color: ${color('brown400')};

    ${StyledImage} {
      transform: scale(1.5);
    }
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-area: image;
  height: ${units(36)};
  overflow: hidden;
  position: relative;
`;

/**
 * `StyledLabel` styled component.
 */

const StyledLabel = styled(Label).attrs({ size: 'small' })`
  color: ${color('brown400')};
  font-style: italic;
  text-transform: uppercase;
`;

/**
 * `InfoWrapper` styled component.
 */

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: info;
  overflow: hidden;
  padding-top: ${units(3)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(CardTitle).attrs({ size: 'small' })`
  margin-bottom: ${units(2.5)};
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Type.Paragraph)`
  color: ${color('grey400')};
  height: ${units(6)};
  margin-bottom: ${units(1.5)};
  overflow: hidden;
  text-overflow: ellipsis;

  /* stylelint-disable */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  display: block;
  /* stylelint-enable */
`;

/**
 * `ReadMore` styled component.
 */

const ReadMore = styled.div`
  border-bottom: 1px solid ${color('brown400')};
  color: ${color('brown400')};
  cursor: pointer;
  display: table;
  text-decoration: none;
  text-transform: uppercase;
  transition: ${theme('animations.defaultTransition')};
  transition-property: border-bottom-color, color;
  width: max-content;

  &:focus,
  &:hover {
    border-bottom-color: ${color.transparentize('brown400', 0.8)};
    color: ${color.transparentize('brown400', 0.8)};
  }
`;

/**
 * `MagazineCard` component.
 */

const MagazineCard = (props: Props): ReactElement => {
  const { locale, translate } = useTranslate();
  const {
    bgColorTheme,
    category,
    lead,
    media,
    slug,
    title
  } = props;

  const routeResolver = useLocalizedRoute();
  const imageUrl = useMemo(() => {
    const mediaFile = head(filter(media, ({ collectionName }) => {
      return collectionName === mediasMagazineName.featuredImages;
    }));

    return mediaFile?.fileUrl ?? assetUrlResolve('/static/images/empty/list@2x.jpg');
  }, [media]);

  const categoryTranslatedKey = useMemo(() => {
    return getKeyFromObject(categoriesValues, category);
  }, [category]);

  return (
    <Card
      bgColorTheme={bgColorTheme}
      href={routeResolver('storytilingDetails', { slug })}
      locale={locale}
    >
      <ImageWrapper>
        <StyledImage
          alt={title}
          layout={'fill'}
          objectFit={'cover'}
          src={imageUrl}
        />
      </ImageWrapper>

      <InfoWrapper>
        {categoryTranslatedKey && (
          <StyledLabel>
            {translate(`common:magazines.categories.${categoryTranslatedKey}`)}
          </StyledLabel>
        )}

        <Title>
          {title}
        </Title>

        <Lead>
          {lead}
        </Lead>

        <ReadMore>
          {translate('common:actions.readMore')}
        </ReadMore>
      </InfoWrapper>
    </Card>
  );
};

/**
 * Default props.
 */

MagazineCard.defaultProps = {
  bgColorTheme: 'grey100'
};

/**
 * Export `MagazineCard` component.
 */

export default MagazineCard;
